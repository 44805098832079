$pink: #ff6881 !default; /* rgb(255,104,129) */
$green: #345c00 !default;

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1120px,
    xxl: 1140px,
);

@import "~bootstrap/scss/bootstrap.scss";

@import url("https://fonts.googleapis.com/css2?family=Funnel+Sans:ital,wght@0,300..800;1,300..800&family=Murecho:wght@100..900&display=swap");
$font-family-sans-serif: "Funnel Sans";


:root {
    --body-background: #f2f2f2;
    --body-color: #444;
    --heading-color: black;
    --action-color: #d64045;
}

body {
    background: linear-gradient(
        100deg,
        rgba(112, 204, 132, 0.13) 3%,
        rgba(112, 204, 132, 0.45) 89%
    );
    font-size: 14px;
    transition: all 200ms linear;
    font-family: $font-family-sans-serif;
    font-weight: 200;
    font-optical-sizing: auto;
    line-height: 1.7;
    color: #545050;
}
.serif-font {
    font-family: $font-family-sans-serif;
}

main {
    min-height: 500px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
.navbar-light .navbar-brand,
.text-dark,
.dropdown-item {
    color: $pink;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
strong {
    font-weight: 600;
}

/* #Navigation
================================================== */

.navbar-wrap {
    background: #def1e3;
    color: $green !important;
    font-size: 16px;

    a {
        &:hover {
            text-decoration: underline;
            color: inherit;
            transition: all 0.4s;
        }
    }
}

h4,
.h4,
h5,
.h5 {
    line-height: 1.4;
}

.font-weight-bold {
    font-weight: 500 !important;
}

a {
    color: $green;
    text-decoration: none;
    transition: all 0.4s;
    &:hover {
        text-decoration: underline;
        color: inherit;
        transition: all 0.4s;
    }
}

.credits {
    cursor: pointer;
    a {
        box-shadow: none !important;
        text-decoration: underline !important;
        &:hover {
            all: unset !important;
            text-decoration: underline !important;
            text-decoration-thickness: 2px !important;
            font-weight: 400 !important;
            color: $green !important;
            transition: none !important;
        }
    }
}

img,
video {
    max-width: 100%;
}

@media only screen and (max-width: 1024px) {
    .page-header.big {
        font-size: 3.5em;
    }
}
@media only screen and (max-width: 767px) {
    .page-header.big {
        font-size: 2.5em;
    }
}

/* page layout */
.page .section-title span {
    font-size: 2rem;
    text-transform: none;
    letter-spacing: 0;
}

.page-content ol,
.page-content ul {
    margin-bottom: 1.5rem;
}

.page-content ol ol,
.page-content ul ul {
    list-style: disc;
    margin-bottom: 0rem;
}

.page-content > * {
    margin-bottom: 1.5rem;
}
.page-content {
    font-size: 1.18em;
    line-height: 1.64;
    color: rgba(39, 58, 47, 0.8);
    border-color: $pink;
    border-width: 3px;
    code {
        font-size: 15px;
    }
    a {
        color: inherit;
        box-shadow:
            inset 0 -1px 0 $pink,
            0 1px 0 $pink;
    }
    a:hover {
        box-shadow:
            inset 0 -30px 0 $pink,
            0 2px 0 $pink;
        text-decoration: none;
    }
    img,
    iframe {
        border-radius: 0.25rem !important;
    }
}

.page-content .h1,
.page-content .h2,
.page-content .h3,
.page-content .h4,
.page-content .h5,
.page-content h1,
.page-content h2,
.page-content h3,
.page-content h4,
.page-content h5 {
    font-weight: 400;
    font-family: $font-family-sans-serif;
    margin-top: 3rem;
}

/* home */
.home-more-button {
    background-color: rgba(255, 104, 129, 0.9);
}

.home-more-button a {
    color: rgba(255, 255, 255, 0.8);
    &:hover {
        color: rgba(255, 255, 255);
        transition: all;
    }
}

.home-panel-pink {
    width: 100%;
    background: linear-gradient(
        100deg,
        rgba(112, 204, 132, 0.13) 3%,
        rgba(112, 204, 132, 0.45) 89%
    );
}

.home-agenda {
    opacity: 0.5;
    font-weight: 200;
}

/* mokublad cards */
.card {
    background: none;
    h4,
    h5,
    h6 {
        color: $pink;
    }
}

.card-title,
.card-subtitle {
    margin-top: 0.5rem !important;
}

.card {
    border: 0;
}
.card .img-thumb {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.card-body img {
    height: 200px;
    object-fit: cover;
}

.hover-opacity:hover {
    transition: all 0.3s;
    opacity: 0.8;
}

/* blog */
h2.posttitle {
    font-weight: 400;
    margin-bottom: 2rem;
}

.article-border {
    border-left: 1px solid #dee2e6 !important;
    border-width: 6px !important;
    border-color: rgba(255, 104, 129, 0.1) !important;
}

blockquote {
    padding-left: 5px;
    font-style: italic;
    border-left: 1px solid #dee2e6 !important;
    border-width: 6px !important;
    border-color: rgba(255, 104, 129, 0.1) !important;
}

.prevnextlinks .thepostlink {
    padding: 20px 0;
    font-size: 17px;
    display: block;
    color: #111;
    font-weight: 500;
}
.prevnextlinks {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    margin-left: 0;
    margin-right: 0;
}
.prevnextlinks .rightborder {
    border-right: 1px solid #eee;
}

/* footer */
.footer {
    margin-top: 4rem;
    padding: 30px 0;
    ul li {
        display: inline-block;
        padding: 10px;
    }
}

// mokumap

#mokumap {
    width: 100%;
    height: 100%;
    min-height: 400px;
}

.gps-marker::after {
    content: "📍";
}

.sItem-container {
    width: 33.33333%;
}

.sItem--hidden {
    display: none;
}

.map-container {
    overflow: hidden;
}

.shop-container {
    max-height: 50vh;
    overflow-y: auto;
}

.iframe-youtube { 
    width: 100%;
    aspect-ratio: 16 / 9;
  }

img.flag {
    height: 12px !important;
    border-radius: 0rem !important;
}

